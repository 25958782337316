<template>
  <div class="searchbar">
    <v-popover
      id="snippet-popover"
      ref="snippetSearch"
      popover-class="snippet-search notif-popover-snippet"
      trigger="manual"
      :placement="'top'"
      :show="!snippetClosed"
      :offset="isMobile ? 0 : 12"
    >
      <InputBox
        id="search-input"
        ref="inputbox"
        v-model="searchterm"
        :focus-shortkey="['alt', 's']"
        class="searchbar-input tooltip-target"
        :placeholder="placeholder"
        :aria-label="placeholder"
        aria-describedby="result-count"
        :auto-complete="false"
        :prepends="!searchInProgress ? prepends : prependsInProgress"
        :appends="appends"
        :focus="isMobile"
        form-size="sm"
        @click="openSnippetBar"
        @keyup.enter.native="emitSearchEvent(searchterm)"
      />
      <SnippetSearch
        slot="popover"
        container="body"
        class="tooltip-content snippet-search"
        :search-term="searchterm"
        :previous-search-term="previousTerm"
        :search-in-progress="searchInProgress"
        @showResults="emitSearchEvent(searchterm)"
        @showAdvanced="openSearchBarFromSnippet"
        @searchGlobal="searchGlobal"
        @showPrevious="showPrevious"
      />
    </v-popover>
    <v-popover
      ref="advancedSearch"
      popover-class="advanced-search notif-popover"
      offset="16"
      trigger="manual"
      :open="isAdvancedSearchOpen"
      :auto-hide="false"
      :placement="'top'"
      @hide="closeSearchBar"
    >
      <AdvancedSearch
        slot="popover"
        :key="searchPathSingleFileView"
        v-click-outside="onClickOutside"
        container="body"
        class="tooltip-content advanced-search"
        :search-in-progress="searchInProgress"
        :input-search-term="searchterm"
        :search-path-single-file-view="searchPathSingleFileView"
        :is-open="isAdvancedSearchOpen"
        @filePickerOpened="handleFilePickerState"
        @cancelSearch="cancelSearch"
        @searchEvent="emitAdvancedSearchEvent"
        @updateSearchBar="saveSearchTerm"
        @hide="closeSearchBar"
      />
    </v-popover>

    <div class="input-group-append">
      <div class="col float-right p-0 m-0 pl-3">
        <a
          v-if="hasSearched"
          v-a11ybutton="() => emitRefreshedSearchEvent(lastterm)"
          tabindex="0"
          @click="emitRefreshedSearchEvent(lastterm)"
        >
          <FatSearchIcon class="filled" />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import AdvancedSearch from './Search/AdvancedSearch';
import SnippetSearch from './Search/SnippetSearch';
import FatSearchIcon from 'common/assets/fatsearchicon.svg';
import responsivenessMixin from '@/mixins/responsivenessMixin';
import { mapGetters, mapState } from 'vuex';
import InputBox from 'common/components/InputBox';
import _ from 'lodash';

export default {
  components: {
    AdvancedSearch,
    SnippetSearch,
    FatSearchIcon,
    InputBox,
  },
  mixins: [responsivenessMixin],
  props: {
    searchPathSingleFileView: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      placeholder: this.$t('Search'),
      lastterm: '',
      isAdvancedSearchOpen: false,
      snippetClosed: true,
      searchterm: '',
      previousReady: false,
      searchLoc: '',
      previousTerm: '',
      prepends: [{ icon: 'search', iconBold: true }],
      lockAdvancedSearch: false,
      filePickerOpened: false,
      updated: 0,
      defaultPayload: {
        searchstring: '',
        searchloc: '',
        minsize: 0,
        maxsize: 0,
        modifiedstart: '',
        modifiedend: '',
        searchscope: 0,
        searchresulttype: '',
        searchattr_total: 0,
        sortby: 'DATE',
        sortdirection: -1,
        filetype: 0,
        start: 0,
        size: 100,
        maxsearchentries: -1,
        refresh: 1,
      },
      prependsInProgress: [
        {
          icon: 'spinner',
          iconBold: false,
          animation: 'spin',
        },
      ],
    };
  },
  computed: {
    ...mapState('files', ['searchInProgress', 'sidebar']),
    ...mapGetters('core', ['startSearchInCurrentlocation']),
    hasSearched() {
      return !_.isEmpty(this.$store.state.files.searchQuery);
    },
    appends() {
      let _appends = [];
      if (this.searchterm)
        _appends.push({
          icon: 'times',
          iconBold: this.searchInProgress,
          action: () => {
            if (this.searchInProgress) {
              this.cancelSearch();
            } else {
              this.searchterm = '';
            }
          },
          title: this.searchInProgress ? this.$t('Cancel search') : '',
        });
      _appends.push({
        icon: !this.isMobile
          ? this.isAdvancedSearchOpen
            ? 'caret-up'
            : 'caret-down'
          : 'caret-down',
        iconBold: true,
        action: this.toggleSearchBar,
        title: this.$t('Press enter to open advanced search window'),
        ref: 'chevron',
      });
      return _appends;
    },
    singleFileViewSearch() {
      return !_.isEmpty(this.searchPathSingleFileView);
    },
    searchFromCurrentLocation() {
      return this.startSearchInCurrentlocation && this.$route.name !== 'files';
    },
  },
  watch: {
    searchPathSingleFileView() {
      this.updated++;
    },
  },
  mounted() {
    if (this.singleFileViewSearch) {
      this.$store.commit('files/addToStore', {
        key: 'isSingleFileViewSearch',
        value: true,
      });
    }

    if (this.isMobile) {
      setTimeout(() => {
        this.openSnippetBar();
      }, 300);
    }
  },
  methods: {
    saveSearchTerm(payload) {
      this.searchterm = payload.searchterm;
      this.lastterm = payload.searchterm;
    },
    async getPreviousSearchTerm() {
      if (!_.isEmpty(this.$store.state.files.searchQuery)) {
        const response = await this.$store.dispatch('files/loadLastQuery');
        return response;
      }

      return '';
    },
    async showPrevious(payload) {
      this.$emit('searchEvent');
      await this.$store.dispatch('files/search', {
        ...this.defaultPayload,
        searchstring: payload.searchterm,
      });

      this.$emit('searchEventCompleted');
    },
    async openSearchBar() {
      this.isAdvancedSearchOpen = true;
      this.snippetClosed = true;
      this.$refs.snippetSearch.hide();
      this.$refs.advancedSearch.show();
      this.$emit('show');

      //workaround to change the target of popover
      const target = this.$refs.inputbox.$refs.chevron;
      this.$refs.advancedSearch.$refs.trigger = target[0].$el;
      this.$refs.advancedSearch.$el = target[0].$el;
    },
    openSearchBarFromSnippet() {
      // remove from queue to avoid click-outside being triggered
      // after openSearchBar method
      setTimeout(() => {
        this.openSearchBar();
      });
    },
    closeSearchBar() {
      this.isAdvancedSearchOpen = false;
    },
    toggleSearchBar() {
      this.isAdvancedSearchOpen ? this.closeSearchBar() : this.openSearchBar();
    },
    async openSnippetBar() {
      this.snippetClosed = false;
      this.previousTerm = await this.getPreviousSearchTerm();
      this.$refs.snippetSearch.show();
    },
    searchGlobal() {
      const GLOBAL_SEARCH = true;
      this.emitSearchEvent(this.searchterm, GLOBAL_SEARCH);
    },
    async emitSearchEvent(term, isGlobalSearch = false) {
      let searchLocation = this.searchFromCurrentLocation ? this.sidebar.path : '';
      if (isGlobalSearch) searchLocation = '';

      // 2 character cutoff
      if (term.length >= 2) {
        this.lastterm = term;
        this.$emit('searchEvent', {
          ...this.defaultPayload,
          searchstring: term,
          searchloc: searchLocation,
        });

        if (this.searchPathSingleFileView !== '') {
          searchLocation = this.searchPathSingleFileView;
        }

        await this.$store.dispatch('files/search', {
          ...this.defaultPayload,
          searchstring: term,
          searchloc: searchLocation,
        });
        this.$emit('searchEventCompleted');
      }
    },
    async emitAdvancedSearchEvent(payload) {
      this.$refs.advancedSearch.hide();
      this.defaultPayload = payload;
      await this.$store.dispatch('files/search', payload);
      this.$emit('searchEventCompleted');
      this.$emit('searchEvent', payload);
    },
    async emitRefreshedSearchEvent() {
      this.searchterm = this.lastterm;
      this.$emit('searchEvent');

      await this.$store.dispatch('files/search', {
        ...this.defaultPayload,
        searchstring: this.lastterm,
        searchloc: this.defaultPayload.searchloc,
      });

      this.$emit('searchEventCompleted');
    },
    async cancelSearch() {
      this.$refs.advancedSearch.hide();
      if (!_.isEmpty(this.$store.state.files.searchId)) {
        await this.$store.dispatch('files/cancelSearch');
      }

      if (this.isMobile) {
        setTimeout(() => {
          this.openSnippetBar();
        }, 300);
      }
      //this.isAdvancedSearchOpen = false;
    },
    handleFilePickerState(state) {
      this.filePickerOpened = state;
    },
    onClickOutside(event) {
      // if not opened
      if (!this.isAdvancedSearchOpen || this.filePickerOpened) return;

      // if clicked on carret icon on search bar
      const searchBarInputEl = document.querySelector('.searchbar-input');
      if (searchBarInputEl.contains(event.target)) return;

      // if datapicker clicked
      const datePickerParent = document.querySelector('.mx-datepicker-popup');
      if (datePickerParent && datePickerParent.contains(event.target)) return;

      // close advanced search on click outside
      this.isAdvancedSearchOpen = false;
    },
  },
};
</script>
<style lang="scss" scoped>
input:focus,
textarea:focus,
select:focus {
  outline: none;
  box-shadow: none;
  border-color: var(--border-color);
}
.searchbar {
  font-size: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 40px;
}
.tooltip {
  &.popover {
    padding-right: 150px;
  }
}
.icon-width {
  width: 15px;
}
.text-black-5 {
  color: var(--text-light);
}
.filled {
  fill: var(--text-light);
}

.snippet-search {
  margin-top: -1rem;

  @media screen and (min-width: 768px) and (max-width: 1200px) {
    margin-top: 0;
  }
}
.small-spinner {
  width: 14px;
  height: 14px;
  border-width: 2px;
  color: #465c6a;
}

@media screen and (min-width: 1200px) {
  .searchbar-input {
    width: 360px;
  }
}

::v-deep .form-group {
  margin: 0;
  padding: 0;
}
</style>
